import React, { FC } from "react"
import { styled } from "@mui/material";

const Title = styled("h5")({
  marginBottom: "5px"
})

const Content = styled("p")({
  fontSize: "14px",
  margin: "0px 0px 10px 0px"
})

const Wrapper = styled("div")({
  borderBottom: "solid 1px #ECECEC",
  width: "100%"
})

type Props = {}
const TokushohouScreen: FC<Props> = (props) => {

  return (
    <>
      <div>

        <h2 style={{textAlign: "center", marginBottom: "70px"}}>特定商取引法に基づく表記</h2>

        <Wrapper>
          <Title>会社名</Title>
          <Content>Public 株式会社</Content>
        </Wrapper>

        <Wrapper>
          <Title>代表者名</Title>
          <Content>松本拓也</Content>
        </Wrapper>

        <Wrapper>
          <Title>所在地</Title>
          <Content>〒150-0001<br />東京都渋谷区神宮前六丁目23番4号 桑野ビル2階</Content>
        </Wrapper>

        <Wrapper>
          <Title>お問い合わせ</Title>
          <Content><a href="https://forms.gle/WRNAvuxfzKfnAXzb9">こちら</a>からお願いします</Content>
        </Wrapper>

        <Wrapper>
          <Title>商品の販売価格</Title>
          <Content>各商品に表記された価格に準じます</Content>
        </Wrapper>

        <Wrapper>
          <Title>お支払い方法</Title>
          <Content>クレジットカード</Content>
        </Wrapper>

        <Wrapper>
        <Title>引き渡し時期</Title>
        <Content>入金確認後、1~7日程度で発送</Content>
        </Wrapper>

        <Title>返品・交換について</Title>
        <Content>取引確定後は原則として返品・交換は不可</Content>
      </div>
    </>

  )

}

export default TokushohouScreen