import React, { FC } from "react";
import { Container, Hidden, styled } from "@mui/material";

type Props = {}

const SellerScreen: FC<Props> = (props) => {

  return (
    <>
      <img src="/seller/fv_word.png" alt="" style={{ width: "100%", marginBottom: 22 }} />

      <div style={{ justifyContent: "center", alignItems: "center", display: 'flex', marginBottom: 20, maxWidth: "100%" }}>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "40%" }}>
          <img src="/seller/ios.png" alt="" style={{ width: "100%" }} />
        </a>
        <div style={{ width: "10px" }}></div>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "42%" }}>
          <img src="/seller/android.png" alt="" style={{ width: "100%" }} />
        </a>
      </div>

      <img src="/seller/fv_photos.png" alt="" style={{ width: "100%" }} />

      <img src="/seller/categories_word.png" alt="" style={{ width: "100%" }} />

      <div className="loop_wrap">
        <img src="/seller/categories.png" alt="" />
        <img src="/seller/categories.png" alt="" />
      </div>

      <img src="/seller/numbers.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/merit.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/steps.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/fee.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/support_word.png" alt="" style={{ width: "100%" }} />

      <a href="https://publiccompany.notion.site/FAQ-0047ec671326415b968b63b474fcbda2?pvs=4" style={{ width: "100%" }} target="_blank">
        <img src="/seller/faq.png" alt="" style={{ width: "100%" }} />
      </a>

      <img src="/seller/lv_word.png" alt="" style={{ width: "100%" }} />

      <div className="loop_wrap">
        <img src="/seller/categories.png" alt="" />
        <img src="/seller/categories.png" alt="" />
      </div>

      <div style={{ justifyContent: "center", alignItems: "center", display: 'flex', marginBottom: 50, maxWidth: "100%" }}>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "40%" }}>
          <img src="/seller/ios.png" alt="" style={{ width: "100%" }} />
        </a>
        <div style={{ width: "10px" }}></div>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "42%" }}>
          <img src="/seller/android.png" alt="" style={{ width: "100%" }} />
        </a>
      </div>

      <img src="/seller/company.png" alt="" style={{ width: "100%" }} />
    </>
  )

}

export default SellerScreen