import React, { FC, useState, useCallback } from "react";
import { styled, Menu, MenuItem, MenuList } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { Close, Menu as MenuIcon } from "@mui/icons-material";

const StyledList = styled(MenuList)({
  borderRadius: "8px",
  width: "300px",
  border: "1px solid #ebebeb",
  outline: "none"
});

const StyledMenuIcon = styled(MenuIcon)({
  color: "#000"
});

const StyledIconButton = styled(IconButton)({
  position: "absolute",
  top: "15px",
  right: "15px",
  backgroundColor: "#fff",
  padding: "5px",
  "&:hover": {
    backgroundColor: "#fff",
  }
});

const StyledCloseIcon = styled(Close)({
  color: "#000"
});


const StyledMenuItem = styled(MenuItem)({
  height: "40px",
  fontSize: "14px"
})

type Props = {}

const CustomMenu: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <>
      <StyledIconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true">
        {anchorEl ? <StyledCloseIcon /> : <StyledMenuIcon />}
      </StyledIconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledList>
          <StyledMenuItem>
            <a href="https://drop.ec/seller" target="_blank" rel="noreferrer" style={{ width: "100%" }}>
              <p>ご販売はこちら</p>
            </a>
          </StyledMenuItem>
          <StyledMenuItem>
            <a href="https://public.st" target="_blank" rel="noreferrer" style={{width: "100%"}}>
              <p>運営会社</p>
            </a>
          </StyledMenuItem>
          <StyledMenuItem>
            <Link to="/terms" style={{width: "100%"}}>
              <p>利用規約</p>
            </Link>
          </StyledMenuItem>
          <StyledMenuItem>
            <Link to="/privacy" style={{width: "100%"}}>
              <p>プライバシーポリシー</p>
            </Link>
          </StyledMenuItem>
          <StyledMenuItem>
            <a href="https://forms.gle/g1ZXPosYVe3d8H4H6" target="_blank" rel="noreferrer" style={{width: "100%"}}>
              <p>お問い合わせ</p>
            </a>
          </StyledMenuItem>
        </StyledList>
      </Menu>
    </>
  )

}

export default CustomMenu;